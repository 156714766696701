import { SiOpenaigym } from "react-icons/si";

export const links = [
  {
    name: "Home",
    path: "/"
  },
  {
    name: "Venue",
    path: "/venue"
  },
  {
    name: "Call",
    path: "/call"
  },
  {
    name: "Registration",
    path: "/registration"
  },
  {
    name: "Program",
    path: "/program"
  },
  {
    name: "Committee",
    path: "/committee"
  }
];

export const programs = [
  {
    id: 1,
    icon: SiOpenaigym,
    title: "Program One",
    info: "Some info",
    path: "/programs/111"
  }
];

export const values = [
  {
    id: 1,
    icon: SiOpenaigym,
    title: "Value One",
    desc: "Description One"
  }
];
