export const datesCall = [
    {
      name: "Short paper submission",
      date: "4 Dec 2023 - 2 Feb 2024"
    },
    {
      name: "Notification of acceptance",
      date: "3 Apr 2024"
    }
    /*{
      name: "Notification of acceptance",
      date: "18 Mar 2024 - 23 Mar 2024"
    }*/
  ];

  export const datesRegistration = [
    /*{
      name: "Registration",
      date: "24 Mar 2024 - 17 Jun 2024"
    },*/
    {
      name: "Early bird registration",
      date: "5 Apr 2024 - 26 Apr 2024"
    },
    {
      name: "Author registration",
      date: "5 Apr 2024 - 26 Apr 2024"
    },
    {
      name: "Registration",
      date: "5 Apr 2024 - 17 Jun 2024"
    }
  ];

  export const datesProgram = [
    {
      name: "hEART 2024 workshops",
      date: " 17 Jun 2024"
    },
    {
      name: "hEART 2024",
      date: "18 Jun 2024 - 20 Jun 2024"
    }
  ];
  