import { datesRegistration } from "./dates";

export const fees = [
  {
    name: "Early bird standard",
    amount: "EUR 550",
    deadline: (datesRegistration[1].date).split("-")[1].trim()
  },
  {
    name: "Early bird student*",
    amount: "EUR 350",
    deadline: (datesRegistration[1].date).split("-")[1].trim()
  },
  {
    name: "Standard",
    amount: "EUR 650",
    deadline: (datesRegistration[0].date).split("-")[1].trim()
  },
  {
    name: "Student",
    amount: "EUR 450",
    deadline: (datesRegistration[0].date).split("-")[1].trim()
  }
];
