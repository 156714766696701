export const feeperks = [
  {
    name:
      "Access to all conference sessions"
   },
   {
    name: "Conference social events on Monday 17 Jun 2024"
  },
   {
    name: "Welcome reception on Tuesday 18 Jun 2024"
  },
  {
    name: "Conference dinner on Wednesday 19 Jun 2024"
  },
  {
    name: "All coffee breaks and lunches during the Conference"
  },
  {
    name: "Conference materials"
  }

];
