import Helsinki1 from "../../images/helsinki1.jpg";
import Map from "../../images/map.png";
import Heymo from "../../images/heymo.jpg";
import Royal from "../../images/royal.jpg";
import Espoo from "../../images/espoo.jpg";
import "./venue.css";
import { FiExternalLink } from "react-icons/fi";
import Instructions from "../../files/Instructions.pdf";

const Venue = () => {
  return (
    <div className="page">
      <div className="container">
        <div className="container-grid">
          <img className="image_left" src={Helsinki1} alt="Helsinki" />
          <img className="image_right" src={Map} alt="Map" />
        </div>
        <p>
          <h3>Aalto University:</h3>
          Aalto University, situated in{" "}
          <a href="https://www.visitespoo.fi/en" target="_blank" rel="noreferrer">
            Espoo
          </a>{" "}
          at the heart of the{" "}
          <a
            href="https://www.myhelsinki.fi/see-and-do/sights"
            target="_blank"
            rel="noreferrer"
          >
            Helsinki
          </a>{" "}
          Metropolitan Area, is a beacon of innovation, excellence, and
          collaboration. The campus is a fusion of contemporary architecture and
          the harmonious natural environment, fostering a creative atmosphere.
          With state-of-the-art research facilities and collaborative spaces,
          Aalto University encourages intellectual exploration and innovative
          thinking. Find more on our{" "}
          <a href="https://aalto.fi/en" target="_blank" rel="noreferrer">
            University
          </a>
          !<h3>How to get there:</h3>
          For those who are traveling from Helsinki center to Aalto University,
          taking the metro is the fastest way to the campus. Please, consider that in summer there will be{" "}
          <a
            href="https://www.hsl.fi/en/hsl/news/news/2024/01/renovation-and-construction-work-to-affect-rail-services-in-2024--central-railway-station-metro-station-to-close-for-the-summer"
            target="_blank"
            rel="noreferrer"
          >
            <b>renovation and construction work</b>
          </a>{" "}
          to affect rail services and the Central Railway Station (Rautatientori)
          metro station. Therefore, we invite you to take care of your commuting
          planning by using, for example, the{" "}
          <a
            href="https://www.hsl.fi/en/tickets-and-fares/hsl-app"
            target="_blank"
            rel="noreferrer"
          >
            HSL
          </a>{" "}
          app.
          {/*The map in the picture
          shows the location of the city center (Rautatientori) and the university, connected by
  a 12 minutes metro ride.*/}
          <h3>The building:</h3>
          The conference is taking place at{" "}
          <a
            href="https://www.aalto.fi/en/locations/undergraduate-centre"
            target="_blank"
            rel="noreferrer"
          >
            Otakaari 1
          </a>
          , Espoo.
          <h3>Furhter information:</h3>
          More information on the facilities can be found {" "}
          <a
            href={Instructions}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>.
        </p>
        <p>
          <div>
          <h3>Accommodation</h3>
          Several hotels are located close to the conference venue or within reach. Special rates are available for hEART 2024 attendees (limited rooms):
          <div className="container-grid">
            <img className="image_left" src={Heymo} alt="Heymo 1 by Sokos Hotels" />
            <div className="image_right">
              <h4>Heymo 1 by Sokos Hotels</h4>
              Standard room in single use 110 eur/night<br/>
              Standard double room 130 eur/night<br/>
              Miestentie 5, 02150, Espoo<br/>
              +358 20 1234 201
              {" "}<a
              target="_blank"
              rel="noreferrer"
              href="https://www.sokoshotels.fi/en/hotels/espoo/heymo-1">
                <FiExternalLink/>
              </a><br/>
              Reservation code: <b>BhEART2024</b>
            </div>
            <img className="image_left" src={Royal} alt="Radisson Blu Plaza Hotel" />
            <div className="image_right">
              <h4>Radisson Blu Plaza Hotel</h4>
              Standard room in single use 180 eur/night<br/>
              Standard double room 200 eur/night<br/>
              Mikonkatu 23, 00100, Helsinki<br/>
              +358 20 1234 703
              {" "}<a
              target="_blank"
              rel="noreferrer"
              href="https://www.radissonhotels.com/en-us/hotels/radisson-blu-helsinki-royal">
                <FiExternalLink/>
              </a><br/>
              Reservation code: <b>hEART</b>
            </div>
            <img className="image_left" src={Espoo} alt="Radisson Blu Hotel Espoo" />
            <div className="image_right">
              <h4>Radisson Blu Hotel Espoo</h4>
              Standard room in single use 140 eur/night<br/>
              Standard twin room 160 eur/night<br/>
              Otaranta 2, 02150, Espoo<br/>
              +358 20 1234 705
              {" "}<a
              target="_blank"
              rel="noreferrer"
              href="https://www.radissonhotels.com/en-us/hotels/radisson-blu-espoo">
                <FiExternalLink/>
              </a><br/>
              Reservation code: <b>hEART</b>
            </div>
          </div></div>
        </p>
      </div>
    </div>
  );
};

export default Venue;


/*import Helsinki1 from "../../images/helsinki1.jpg";
import Map from "../../images/map.png";
import "./venue.css";

const Venue = () => {
  return (
    <div className="page">
      <div className="container container-grid">
      <div className="image_container">
          <h2 style={{align:'left'}}>The Venue</h2>
        </div>
        <div className="image_container">
          <img className="image_left" src={Helsinki1} alt="Helsinki" />
          <div className="wrapping_text">
            <h3>Aalto University:</h3>
            Aalto University, situated in {" "}
            <a href="https://www.visitespoo.fi/en" target="_blank" rel="noreferrer">
              Espoo
            </a> at the heart of the {" "}
            <a href="https://www.myhelsinki.fi/see-and-do/sights" target="_blank" rel="noreferrer">
            Helsinki
            </a>
            {" "} Metropolitan Area, is a beacon of innovation, excellence, and collaboration.
            The campus is a fusion of contemporary architecture and the harmonious natural
            environment, fostering a creative atmosphere. With state-of-the-art research
            facilities and collaborative spaces, Aalto University encourages intellectual
            exploration and innovative thinking. Find more on our {" "}
            <a href="https://aalto.fi/en" target="_blank" rel="noreferrer">
            University
            </a>!
            <h3>How to get there:</h3>
            For those who are traveling from Helsinki center to Aalto University,
            taking the metro is the fastest way to the campus. The picture below shows
            the location of the city center and the university connected by the metro
            line.
            <h3>The building:</h3>
            The conference is taking place at {" "}
            <a href="https://www.aalto.fi/en/locations/undergraduate-centre" target="_blank" rel="noreferrer">
            Otakaari 1
            </a>.
          </div>
          <img className="image_right" src={Map} alt="Map" />
        </div>
      </div>
    </div>
  );
};

export default Venue;*/