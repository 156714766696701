import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../images/logo.webp";
import { links } from "../data/links";
import { VscThreeBars } from "react-icons/vsc";
import { MdOutlineClose } from "react-icons/md";
import "./header.css";

const Header = () => {
  const [isNavShowing, setIsNavShowing] = useState(false);
  return (
    <nav>
      <div className="container nav__container">
        <Link to="/" className="logo" onClick={() => setIsNavShowing(false)}>
          <table cellSpacing="3">
            <tr>
              <td>
                <img
                  style={{ width: 50, height: 50 }}
                  src={Logo}
                  alt="hEART2024"
                />
              </td>
              <td style={{ height: 50, verticalAlign: "middle" }}>
                {} hEART 2024
              </td>
            </tr>
          </table>
        </Link>
        <ul
          className={`nav__links ${isNavShowing ? "show__nav" : "hide__nav"}`}
        >
          {links.map(({ name, path }, index) => {
            return (
              <li className="tab-bar">
                <NavLink
                  to={path}
                  //activeClassName="active"
                  className={({ isActive }) =>
                    isActive ? "active-nav active" : ""
                  }
                  //className={"tab"}
                  onClick={() => setIsNavShowing((prev) => !prev)}
                >
                  {name}
                </NavLink>
              </li>
            );
          })}
        </ul>
        <button
          className="nav__toggle-btn"
          onClick={() => setIsNavShowing((prev) => !prev)}
        >
          {isNavShowing ? <MdOutlineClose /> : <VscThreeBars />}
        </button>
      </div>
    </nav>
  );
};

export default Header;
