import React from "react";
import { Link } from "react-router-dom";
import Logo from "../images/logo.webp";
/*import { FaLinkedin } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";*/
import Logo1 from "../images/logo-86631-1.png";
import Logo2 from "../images/espoologo2.png";
import Logo3 from "../images/visitespoo.jpg";
import Logo4 from "../images/aimsun.jpg";
import "./footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="container footer__container">
        <article>
          <p>
            <table>
              <tr><td><img
                style={{ width: 100, height: 100 }}
                src={Logo}
                alt="hEART2024"
              /></td><td>Aalto University welcomes you to the 12<sup>th</sup> Symposium of
              the European Association for Research in Transportation!</td>
              </tr>
            </table>
          </p>
          <p>
            <h4>For further inquiries about the event, contact{" "}
            <a
              href="mailto: heart24@aalto.fi"
              target="_blank"
              rel="noreferrer noopener"
            >
              heart24@aalto.fi
            </a></h4>
          </p>
        </article>
        <article></article>
        <article>
          <h4>Links</h4>
          <Link to="/">Home</Link>
          <Link to="/venue">Venue</Link>
          <Link to="/call">Call</Link>
          <Link to="/registration">Registration</Link>
          <Link to="/program">Program</Link>
          <Link to="/committee">Committee</Link>
        </article>
        <article>
          <h4>Sponsors</h4>
          <div className="sponsors">
            <img src={Logo1} alt="logo1" style={{ width: 100, height: 100 }} />
            <img src={Logo4} alt="logo4" style={{ width: 90 }} />
            <img src={Logo3} alt="logo3" style={{ width: 120 }} />
            <img src={Logo2} alt="logo2" style={{ width: 100, height: 60 }} />
          </div>
        </article>
      </div>
    </footer>
  );
};

export default Footer;
