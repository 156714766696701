import "./registration.css";
import { datesRegistration } from "../../data/dates";
import { feeperks } from "../../data/feeperks";
import { fees } from "../../data/fees";

const Registration = () => {
  return (
    <div className="page">
      <div className="container">
        <p>
          <h2>Important dates</h2>
          {datesRegistration.map(({ name, date }, index) => {
            return (
              <ul className="multicol-2">
                <li>{name}</li>
                <li style={index>=-1?{textDecoration: 'line-through'}:{textDecoration: 'none'}}>{date}</li>
              </ul>
            );
          })}
        </p>
	      <p>
          <h2>Important Information</h2>
          <ul>
            <li>Each accepted paper requires <b>at least one registration</b>.</li>
            <li>Each attendee can present <b>one paper at most</b>.</li>
            <li>All presenters <b>must register</b> by 26 Apr.</li>
            <li>The early-bird registration deadline for all attendees, including non-authors, <b>is 26 Apr</b>.</li>
          </ul>
        </p>
        <p>
          <h2>Registration fees information</h2>
          <h3>General</h3>
          <table className="floating-table">
            <tbody>
              {fees.map(({ name, amount, deadline }, index) => {
                return (
                  <tr className="table-alternating">
                    <span className="table-name">{name}</span>
                    <span className="table-deadline">until {deadline}</span>
                    <br />
                    <span className="table-amount">{amount}</span>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </p>
        <p>
        <h3>The conference registration fee includes:</h3>
          <div className="multicol-1">
            {feeperks.map(({ name }, index) => {
              return (
                <div>
                  <span>{name}</span>
                </div>
              );
            })}
          </div>
          <h3>*Student fee</h3>
          You are eligible for the student fee if you are a student (Bachelor,
          Master or PhD) within the academic year 2023-2024. If you register as
          a student, you will need to upload your proof during the registration.
          (Either a student ID card including the academic year, a letter from
          your supervisor or a program registration certificate).
        </p>
        {/*<p>
          <h3>Summer school</h3>
        </p>
        <p>
          <h3>Workshops</h3>
        </p>
        <p>
          <h3>Accompanying person</h3>
        </p>
        <p>
          <h3>Registration process</h3>
        </p>*/}
        <h3>Cancellation policy</h3>
        Unpaid reservations will be cancelled by the conference office 4 weeks
        after they have been made. The conference office must be notified in
        writing of cancellation of registration. Please send possible changes
        for registrations and cancellations by{" "}
        <a
          href="mailto: heart24@aalto.fi"
          target="_blank"
          rel="noreferrer noopener"
        >
          email
        </a>
        :{" "}
        <div className="multicol-1">
          <div>
            <span>
              Full refund for cancellations received before 17 May 2024
            </span>
          </div>
          <div>
            <span>
              50% refund for cancellations received before 9 Jun 2024
            </span>
          </div>
          <div>
            <span>No refunds after 9 Jun 2024</span>
          </div>
        </div>
        {/*<p className="coming-soon">
          The details about the registration will be added soon.
        </p>*/}
        <p>
          <div className="multicol2 coming-soon">
            <a
              href="https://link.webropolsurveys.com/EP/4651E695BFBA8F80"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button style={{fontSize:20}} className="btn">Register here!</button>
            </a>
          </div>
        </p>
      </div>
    </div>
  );
};

export default Registration;
