import React from "react";

import AppRouter from "./layout/AppRouter";

function App() {
  return (
    <React.Fragment>
      <AppRouter />
    </React.Fragment>
  );
}

export default App;
