import { datesProgram } from "../../data/dates"
import Mokhtarian from "../../images/Mokhtarian.jpg";
import Papageorgiou from "../../images/Papageorgiou2.jpg";
import Toivonen from "../../images/Toivonen.jpg";
import SimMobility_Aimsun from "../../files/workshop_SimMobility_Aimsun.pdf";
import Excursions from "../../files/excursions.pdf";
import DetailedProgram from "../../files/Program.pdf";
import Instructions from "../../files/Instructions.pdf";
import MATSim from "../../files/MATSim-Program.pdf";
import PhD from "../../files/PhD-School.pdf";
import "./program.css";

const Program = () => {
  return (
    <div className="page">
      <div className="container">
      <p>
        <h2>Important dates</h2>
        {datesProgram.map(({ name, date }, index) => {
          return (
            <ul className="multicol-2">
              <li>{name}</li>
              <li>{date}</li>
            </ul>
          );
        })}
      </p>
      <p>
        The conference will start on 18 Jun at 9 am EET and end on 20 Jun at 1 pm
        EET. The detailed program can be found {" "}
        <a
          href={DetailedProgram}
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>. Further instructions can be found {" "}
        <a
          href={Instructions}
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>.
      </p>
      <p>
        <h1>hEART 2024 Timetable</h1>
          <div className="timetable-container">
            <table className="timetable">
              <thead>
                <tr>
                  <th className="top-left-corner">hEART 2024</th>
                  <th colspan="3">
                    Mon, 17 Jun
                  </th>
                  <th>
                    Tue, 18 Jun
                  </th>
                  <th>
                    Wed, 19 Jun
                  </th>
                  <th className="top-right-corner">
                    Thu, 20 Jun
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="tl">09:00 - 10:00</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td rowSpan={9} className="main-conference">
                    Main conference
                  </td>
                  <td rowSpan={9} className="main-conference">
                    Main conference
                  </td>
                  <td rowSpan={4} className="main-conference">
                    Main conference
                  </td>
                </tr>
                <tr>
                  <td className="tl">10:00 - 11:00</td>
                  <td rowSpan={7} className="workshop change-orientation">
                    <a
                      href={MATSim}
                      target="_blank"
                      rel="noreferrer"
                    >
                      MATSim workshop
                    </a> (register{" "}
                    <a
                      href="https://matsim.payrexx.com/en/pay?tid=f7c50c4a"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>)
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td className="tl">11:00 - 12:00</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td className="tl">12:00 - 13:00</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td className="tl">13:00 - 14:00</td>
                  <td rowSpan={4} className="workshop change-orientation">
                    <a
                      href={PhD}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Free PhD school
                    </a>
                  </td>
                  <td></td>
                  <td rowSpan={5} className="workshop">
                    <a
                      href={SimMobility_Aimsun}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SimMobility & Aimsun
                    </a>
                    <br />
                    free workshop
                  </td>
                </tr>
                <tr>
                  <td className="tl">14:00 - 15:00</td>
                  <td rowSpan={3} className="workshop change-orientation">
                    <a
                      href="https://www.helsinki.fi/en/researchgroups/spatiotemporal-data-analysis/research/aiforlessauto"
                      target="_blank"
                      rel="noreferrer"
                    >
                      AIforLEssAuto
                    </a> <br /> free workshop
                  </td>
                </tr>
                <tr>
                  <td className="tl">15:00 - 16:00</td>
                </tr>
                <tr>
                  <td className="tl">16:00 - 17:00</td>
                </tr>
                <tr>
                  <td className="tl">17:00 - 18:00</td>
                  <td rowSpan={3} colSpan={3} className="leisure">
                    <a
                      href={Excursions}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Excursions
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="tl">18:00 - 19:00</td>
                  <td rowSpan={3} className="leisure">
                    Welcome reception
                  </td>
                  <td rowSpan={4} className="leisure">
                    Gala dinner
                  </td>
                </tr>
                <tr>
                  <td className="tl">19:00 - 20:00</td>
                </tr>
                <tr>
                  <td className="tl">20:00 - 21:00</td>
                </tr>
                <tr>
                  <td className="tl bottom-left-corner">21:00 - 22:00</td>
                </tr>
                {
                  //generateTimetableRows()
                }
              </tbody>
            </table>
          </div>
        </p>
      {/*<p>
        <h2>The schedule</h2>
        <table>
          <tr>
            <td></td>
            <td className="table-header">Monday Jun 10</td>
            <td className="table-header">Tuesday Jun 11</td>
            <td className="table-header">Wednesday Jun 12</td>
            <td className="table-header">Thursday Jun 13</td>
            <td className="table-header">Friday Jun 14</td>
          </tr>
          <tr className="table-content">
            <td className="table-schedule">08:30-09:00</td>
            <td className="table-break">Coffee break</td>
            <td className="table-break">Coffee break</td>
            <td className="table-break">Coffee break</td>
            <td className="table-break">Coffee break</td>
            <td className="table-break">Coffee break</td>
          </tr>
          <tr>
            <td className="table-schedule">09:00-12:00</td>
            <td className="table-presentation1">Something</td>
            <td className="table-presentation1">Something</td>
            <td className="table-presentation1">Something</td>
            <td className="table-presentation1">Something</td>
            <td className="table-presentation1">Something</td>
          </tr>
          <tr>
            <td className="table-schedule">12:00-13:30</td>
            <td className="table-break">Lunch</td>
            <td className="table-break">Lunch</td>
            <td className="table-break">Lunch</td>
            <td className="table-break">Lunch</td>
            <td className="table-break">Lunch</td>
          </tr>
          <tr>
            <td className="table-schedule">13:30-17:00</td>
            <td className="table-presentation2">Something else</td>
            <td className="table-presentation2">Something else</td>
            <td className="table-presentation2">Something else</td>
            <td className="table-presentation2">Something else</td>
            <td className="table-presentation2">Something else</td>
          </tr>
          <tr>
            <td className="table-schedule">17:00-21:00</td>
            <td></td>
            <td></td>
            <td className="table-leisure">Cruise</td>
            <td className="table-leisure">Sauna</td>
            <td></td>
          </tr>
        </table>
      </p>*/}
      <p>
        <h2>Keynote speakers</h2>
        <div className="image-container">
          <div className="image-frame">
            <img className="image" src={Mokhtarian} alt="Patricia L. Mokhtarian" /><br/>
            <h3>Patricia L. Mokhtarian <br/> Georgia Tech</h3>
          </div>
          <div className="image-frame">
            <img className="image" src={Papageorgiou} alt="Markos Papageorgiou" /><br/>
            <h3>Markos Papageorgiou <br/> Technical University of Crete</h3>
          </div>
          <div className="image-frame">
            <img className="image_" src={Toivonen} alt="Tuuli Toivonen" /><br/>
            <h3>Tuuli Toivonen <br/> University of Helsinki</h3>
          </div>
        </div>
      </p>
      </div>
    </div>
  );
};

export default Program;
