import { topics } from "../../data/topics";
import { datesCall } from "../../data/dates";
import latexTemplate from "../../files/latex-template.zip"
import wordTemplate from "../../files/word-template.dotx"
import "./call.css";
import Aalto2 from "../../images/aalto2.png";

const Call = () => {
  return (
    <div className="page">
      <div className="container">
        <p>
          <h2>Relevant topics</h2>
          The hEART 2024 Scientific Committee welcomes the submission of high
          quality abstracts focusing on a wide variety of mobility, transport
          and logistics related topics covering:
          <ul className="multicol-3">
            {topics.map(({ name }, index) => {
              return <li>{name}</li>;
            })}
            <li>
              <img src={Aalto2} style={{ width: 300, height: 200 }} alt="Aalto 2" />
            </li>
          </ul>
          The final decision whether an abstract submission is accepted will be
          taken by the Scientific Committee.
        </p>
        <p>
          <h2>Submission guidelines</h2>
          Short papers of 2000-3000 words are invited for submission by 2
          Feb 2024. Submissions will be reviewed by members of the
          scientific committee. The short papers should clearly state the
          research goals, elaborate methods and discuss the results in order to
          allow the scientific committee to assess their quality. Ongoing research is not preferred as we are expecting clear indication of the
          conclusions from the research. Only original contributions are
          considered for presentation at the conference. A maximum of one
          presentation is allowed per person attending the conference. This
          means that an author can be co-author in multiple papers, but is not
          allowed to present more than one paper.
        </p>
        <p>
          <h2>Important dates</h2>
          {datesCall.map(({ name, date }, index) => {
            return (
              <ul className="multicol-2">
                <li>{name}</li>
                <li style={index===0 || index===1?{textDecoration: 'line-through'}:{textDecoration: 'none'}}>{date}</li>
              </ul>
            );
          })}
        </p>
        <p>
          <h2>Submission instructions</h2>
          Submissions are to be written in English and should be submitted
          electronically using the
          {/*<a href="https://easychair.org/conferences/?conf=heart24"
            target="_blank"
            rel="noopener noreferrer">
        Easychair</a>*/} Easychair portal.
          
          All submissions must be relevant to the conference topics. Authors
          will be asked to select the themes from the topic list that suit best
          to their submission. This is to assist with the review process and the
          creation of the conference program itself. Authors will be requested
          to submit a summary (max. 150 words) as well as a short paper (max.
          3000 words). Please use the provided templates for Word or LaTeX.
          Please upload your short paper as a PDF file and name it
          as follows: “last name_first name_hEART2024”.
          <div className="multicol2 coming-soon">
            <a href={latexTemplate}
              download="latex-template"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn">Download LaTeX template</button>
            </a>
            <a href={wordTemplate}
              download="word-template"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn">Download Word template</button>
            </a>
          </div>
        </p>
        {/*<p>
          <div className="multicol2 coming-soon">
            <a href="https://easychair.org/conferences/?conf=heart24"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button disabled={true} style={{fontSize:20}} className="btn">Submit here!</button>
            </a>
          </div>
          </p>*/}
      </div>
    </div>
  );
};

export default Call;