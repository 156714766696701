import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Home from "../pages/home/Home";
import Venue from "../pages/venue/Venue";
import Call from "../pages/call/Call";
import Registration from "../pages/registration/Registration";
import Program from "../pages/program/Program";
import Committee from "../pages/committee/Committee";
import NotFound from "../pages/notFound/NotFound";
import "./layout.css";

const AppRouter = () => {
  return (
    <div className="page-container">
      <BrowserRouter>
        <header>
          <Header />
        </header>
        <div className="content-wrap">
          <Routes>
            <Route index element={<Home />} />
            <Route path="call" element={<Call />} />
            <Route path="venue" element={<Venue />} />
            <Route path="registration" element={<Registration />} />
            <Route path="committee" element={<Committee />} />
            <Route path="program" element={<Program />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <footer>
          <Footer />
        </footer>
      </BrowserRouter>
    </div>
  );
};

export default AppRouter;
