export const topics = [
  {
    name: "Activity-based modelling"
  },
  {
    name: "Automated and connected driving"
  },
  {
    name: "Big data analytics"
  },
  {
    name: "Cycling and walking behaviour and design"
  },
  {
    name: "Discrete choice modelling"
  },
  {
    name: "Electrification and decarbonization of transport"
  },
  {
    name: "Multi-modal transport"
  },
  {
    name: "Operations research applications"
  },
  {
    name: "Pricing and capacity optimization"
  },
  {
    name: "Shared mobility"
  },
  {
    name: "Traffic flow theory"
  },
  {
    name: "Traffic, network, and mobility management"
  },
  {
    name: "Transport economics and policy"
  },
  {
    name: "Transport network modelling"
  },
  {
    name: "Urban freight transport and city logistics"
  }
];
