// import { MdEmail } from "react-icons/md";
import "./committee.css";

const Committee = () => {
  return (
    <div className="page">
      <div className="container">
        <p>
          <h2>Local Organizing Committee</h2>
          The symposium is organised by the{" "}
          <a
            href="https://www.aalto.fi/en/department-of-built-environment/spatial-planning-and-transportation-engineering"
            target="_blank"
            rel="noreferrer noopener"
          >
            Spatial Planning and Transportation Engineering group
          </a>
          ,{" "}
          <a
            href="https://www.aalto.fi/en/department-of-built-environment"
            target="_blank"
            rel="noreferrer noopener"
          >
            Department of Built Environment
          </a>
          ,{" "}
          <a
            href="https://www.aalto.fi/en/school-of-engineering"
            target="_blank"
            rel="noreferrer noopener"
          >
            School of Engineering
          </a>
          ,{" "}
          <a
            href="https://www.aalto.fi/en/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Aalto University.
          </a>{" "}
          <br />
          <p className="multicol-2_">
            Prof. Claudio Roncoli <br />
            Dr. Shaghayegh (Shaya) Vosough <br />
            Dr. Ramin Niroumand <br />
            Dr. Charalampos (Haris) Sipetas <br />
            Mr. Francesco Vitale <br />
            Mr. Serio Agriesti <br />
            Ms. Liva Westerback <br />
            Ms. Heqi Wang <br />
            Prof. Miloš Mladenović <br />
            Prof. Prottoy Akbar <br />
            Dr. Murat Bayrak <br />
            Dr. Ze Zhou <br />
            Ms. Samira Dibaj <br />
            Mr. Muhammad Haris <br />
            Mr. Yuanxiang Yang
          </p>
        </p>
        <p>
          <h2>Steering Committee</h2>
          Prof. Elisabetta Cherchi (Newcastle University) - Chair<br />
          Prof. Constantinos Antoniou (Technische Universität München) <br />
          Prof. Michel Bierlaire (Ecole Polytechnique Fédérale de Lausanne)<br />
          Prof. Maria Borjesson (Swedish National Road and Transport Research Institute)<br />
          Prof. Nikolas Geroliminis (Ecole Polytechnique Fédérale de Lausanne)<br />
          Prof. Francisco Pereira (Danish Technical University)<br />
          Prof. Shadi Sharif Azadeh (Delft University of Technology)<br />
        </p>
        <h2>Scientific Committee</h2>
        <p className="multicol-4">
          Prottoy Akbar <br />
          Mostafa Ameli <br />
          Constantinos Antoniou <br />
          Bilge Atasoy <br />
          Kay Axhausen <br />
          Sergio Batista <br />
          Shlomo Bekhor <br />
          Michel Bierlaire <br />
          Klaus Bogenberger <br />
          Guido Cantelmo <br />
          Oded Cats <br />
          Elisabetta Cherchi <br />
          Francesco Ciari <br />
          Richard Connors <br />
          Francesco Corman <br />
          Nima Dadashzadeh <br />
          Anargiros Delis <br />
          José Escribano <br />
          Domokos Esztergár-Kiss <br />
          Martin Fellendorf <br />
          Gunnar Flötteröd <br />
          Bernhard Friedrich <br />
          Markus Friedrich <br />
          Jack Haddad <br />
          Benjamin Heydecker <br />
          Simon Hu <br />
          Erik Jenelius <br />
          Martin Kagerbauer <br />
          Ioannis Kaparias <br />
          Anastasios Kouvelas <br />
          Matthias Kowald <br />
          Rafal Kucharski <br />
          Ludovic Leclercq <br />
          Carlos Lima Azevedo <br />
          Virginie Lurkin <br />
          Yousef Maknoon <br />
          Michail Makridis <br />
          Irene Martínez Josemaría <br />
          Miloš Mladenović <br />
          Rolf Moeckel <br />
          Marcello Montanino <br />
          Bat-Hen Nahmias-Biran <br />
          Ramin Niroumand <br />
          Steve O'Hern <br />
          Michele Ottomanelli <br />
          Latifa Oukhellou <br />
          Ioannis Papamichail <br />
          Mohsen Ramezani <br />
          Stefano Ricci <br />
          Marco Rinaldi <br />
          Claudio Roncoli <br />
          Josep-Maria Salanova <br />
          Shadi Sharif Azadeh <br />
          Yoram Shiftan <br />
          Charalampos Sipetas <br />
          Ioanna Spyropoulou <br />
          Aleksandar Stevanovic <br />
          Yusak Susilo <br />
          Chris Tampere <br />
          Tomer Toledo <br />
          Pieter Vansteenwegen <br />
          Silvia Varotto <br />
          Francesco Viti <br />
          Shaghayegh Vosough <br />
          George Yannis <br />
          Mehmet Yildirimoglu
        </p>
      </div>
    </div>
  );
};

export default Committee;
