import { Link } from "react-router-dom";
import Aalto1 from "../../images/aalto1.png";
import "./home.css"
import { datesCall, datesRegistration, datesProgram } from "../../data/dates";
import SI_2 from "../../images/SI_2.png";
import SI_2pdf from "../../files/SI_2.pdf";
import DetailedProgram from "../../files/Program.pdf";
import Instructions from "../../files/Instructions.pdf";
// import { MdNewLabel } from "react-icons/md";

const Home = () => {
  return (
    <div className="page">
      <div className="container container-grid">
        <div className="home-left">
          <h4>
            12<sup>th</sup> Symposium of the European Association for Research
            in Transportation
          </h4>
          <h1>18-20 June 2024</h1>
          <p>
            {" "}
            Aalto University is delighted to host the 12<sup>th</sup>{" "}
            Symposium of the European Association for Research in
            Transportation (
            <a
              href="https://transp-or.epfl.ch/heart/"
              target="_blank"
              rel="noreferrer"
            >
              hEART
            </a>
            ) in Finland. The aim of hEART is to provide a platform for
            scientific discussion and collaboration by bringing together
            leading experts and talented young scholars.
          </p>
          <p>
            <h2>
              News
            </h2>
            <news-container>
              <piece-of-news>
                <news-identifier></news-identifier>
                <the-news>
                  <news-title style={{color:'dodgerblue'}}>
                    <a
                      href="https://transp-or.epfl.ch/heart/2024.php"
                      target="_blank"
                      rel="noreferrer"
                    >
                      The short papers are now available here!
                    </a>
                  </news-title>
                  <news-date>15 Aug 2024</news-date>
                  <news-body>
                    Take a look at the short papers showcased at the conference.
                  </news-body>
                </the-news>
              </piece-of-news>
              <br />
              <piece-of-news>
                <news-identifier></news-identifier>
                <the-news>
                  <news-title style={{color:'dodgerblue'}}>
                    <a
                      href="https://drive.google.com/drive/folders/1Z3Y0lh1fXvOOgO8inA0Dv-H7vgDjez7I?usp=sharing"
                      target="_blank"
                      rel="noreferrer"
                    >
                      hEART 2024 is over: photos available here!
                    </a>
                  </news-title>
                  <news-date>10 Jul 2024</news-date>
                  <news-body>
                  <video width="600" controls>
                    <source src={`${process.env.PUBLIC_URL}/hEART2024_highlights.mp4`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  </news-body>
                </the-news>
              </piece-of-news>
              <br />
              <piece-of-news>
                <news-identifier></news-identifier>
                <the-news>
                  <news-title style={{color:'dodgerblue'}}>
                    <a
                      href={Instructions}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Further instructions for the attendees are now available!
                    </a>
                  </news-title>
                  <news-date>29 May 2024</news-date>
                  <news-body>
                    Check out the information on the facilities, instructions, events, and more.
                  </news-body>
                </the-news>
              </piece-of-news>
              <br />
              <piece-of-news>
                <news-identifier></news-identifier>
                <the-news>
                  <news-title style={{color:'dodgerblue'}}>
                    <a
                      href={DetailedProgram}
                      target="_blank"
                      rel="noreferrer"
                    >
                      The detailed program is now available!
                    </a>
                  </news-title>
                  <news-date>28 May 2024</news-date>
                  <news-body>
                    Check out the conference schedule information.
                  </news-body>
                </the-news>
              </piece-of-news>
              <br />
              <piece-of-news>
                <news-identifier></news-identifier>
                <the-news>
                  <news-title style={{color:'dodgerblue'}}>
                    <a
                      href="https://link.webropolsurveys.com/EP/4651E695BFBA8F80"
                      target="_blank"
                      rel="noreferrer"
                    >
                      The registration portal for attendees to hEART 2024 is open!
                      </a>
                  </news-title>
                  <news-date>5 Apr 2024</news-date>
                  <news-body>
                    Please note the following important information:
                    <ul>
                      <li>Each accepted paper requires <b>at least one registration</b>.</li>
                      <li>Each attendee can present <b>one paper at most</b>.</li>
                      <li>All presenters <b>must register</b> by 26 Apr.</li>
                      <li>The early-bird registration deadline for all attendees, including non-authors, <b>is 22 Apr</b>.</li>
                    </ul>
                  </news-body>
                </the-news>
              </piece-of-news>
              <br />
              <piece-of-news>
                <news-identifier></news-identifier>
                <the-news>
                  <news-title style={{ color: "dodgerblue" }}>
                  <a
                      href={SI_2pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    Special Issue in Journal of Cycling and Micromobility
                    Research: Micromobility Solutions
                  </a>
                  </news-title>
                  <news-date>5 Apr 2024</news-date>
                  <news-body>
                    <img
                      src={SI_2}
                      className="floating-image"
                      style={{ width: 150 }}
                      alt="SI_2"
                    />
                    The issue focuses on research on shared micromobility
                    systems, electric micromobility technology, and their
                    integration with transportation networks and infrastructure.
                    Targeted papers could also include studies on user behavior,
                    system optimization, and regulatory frameworks. Through this
                    special issue, we aim to foster a deeper understanding of
                    the role of micromobility and cycling in shaping modern
                    urban transportation systems. For more details about the
                    topics that are of relevance and important dates please
                    click {" "}
                    <a
                      href={SI_2pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                    >here</a>. We invite accepted conference papers as well as
                    papers submitted to the conference but not selected for
                    presentation. This is to enhance the publication's
                    comprehensiveness and promote a diverse range of
                    perspectives.
                  </news-body>
                </the-news>
              </piece-of-news>
              <br />
              <piece-of-news>
                <news-identifier></news-identifier>
                <the-news>
                  <news-title style={{color:'dodgerblue'}}>
                    <a
                      href="https://journals.open.tudelft.nl/ejtir"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Special Issue in European Journal of Transport and Infrastructure Research
                      </a>
                  </news-title>
                  <news-date>1 Mar 2024</news-date>
                  <news-body>The Conference will be linked with a special issue in {" "}
                    <a
                      href="https://journals.open.tudelft.nl/ejtir"
                      target="_blank"
                      rel="noreferrer"
                    >
                      European Journal of Transport and Infrastructure Research (EJTIR)
                    </a>. This issue will include a list of selected peer-reviewed papers that will be freely accessible. EJTIR covers a wide range of transport related topics, including behavioral, organizational, economic, and/or public policy dimensions of the planning and operations of transport systems. More details about the special issue linked with hEART 2024 and important dates will follow soon. Stay tuned!
                  </news-body>
                </the-news>
              </piece-of-news>
              <br/>
              <piece-of-news>
                <news-identifier></news-identifier>
                <the-news>
                  <news-title style={{color:'dodgerblue'}}>
                    Short paper submission deadline closed!
                  </news-title>
                  <news-date>5 Feb 2024</news-date>
                  <news-body>The notifications of acceptance will be sent by 23 Mar 2024.</news-body>
                </the-news>
              </piece-of-news>
              <br/>
              <piece-of-news>
                <news-identifier></news-identifier>
                <the-news>
                  <news-title style={{color:'dodgerblue'}}>
                    Short paper submission deadline extended!
                  </news-title>
                  <news-date>18 Jan 2024</news-date>
                  <news-body>Due to multiple requests, the submission deadline has been extended to 2 Feb 2024. Note that this will be the <b>final deadline</b>.</news-body>
                </the-news>
              </piece-of-news>
              <br/>
              <piece-of-news>
                <news-identifier></news-identifier>
                <the-news>
                  <news-title>
                    <Link to="/venue">
                      Information about accommodation is now available!
                    </Link>
                  </news-title>
                  <news-date>18 Jan 2024</news-date>
                  <news-body>Check out which options are available for you while enjoying the conference.</news-body>
                </the-news>
              </piece-of-news>
              <br/>
              <piece-of-news>
                <news-identifier></news-identifier>
                <the-news>
                  <news-title style={{color:'dodgerblue'}}>
                    Short paper submission now open!
                  </news-title>
                  <news-date>4 Dec 2023</news-date>
                  <news-body>We welcome you to submit your short paper.</news-body>
                </the-news>
              </piece-of-news>
            </news-container>
            
          </p>
        </div>
        <div className="home-right">
          <p>
            <img src={Aalto1} alt="hEART2024" />
          </p>
          <p>
            <h2>Important dates</h2>
            {datesCall.map(({ name, date }, index) => {
              return (
                <ul className="multicol-2">
                  <li>{name}</li>
                  <li style={index>=-1?{textDecoration: 'line-through'}:{textDecoration: 'none'}}>{date}</li>
                </ul>
              );
            })}
            {datesRegistration.map(({ name, date }, index) => {
              return (
                <ul className="multicol-2">
                  <li>{name}</li>
                  <li style={index>=-1?{textDecoration: 'line-through'}:{textDecoration: 'none'}}>{date}</li>
                </ul>
              );
            })}
            {datesProgram.map(({ name, date }, index) => {
              return (
                <ul className="multicol-2">
                  <li>{name}</li>
                  <li >{date}</li>
                </ul>
              );
            })}
          </p>
        </div>
      </div>  
    </div>
  );  
};

export default Home;